class ImageSlider {
    constructor(el) {
        this.root = el;

        this.breakpoint = 1024;

        this.container = {
            inner: this.root.querySelector("[data-container-inner"),
            outer: this.root.querySelector("[data-container-outer"),
        };

        this.updateOuterContainerHeight();
        this.updateSlider();

        window.addEventListener("scroll", () => {
            this.updateSlider();
        });

        window.addEventListener("resize", () => {
            this.updateOuterContainerHeight();
            this.updateSlider();
        });
    }

    updateSlider() {
        if (innerWidth < this.breakpoint) {
            this.container.inner.removeAttribute("style");
            return;
        }

        const offset = this.getProgress() * this.getTransformDistance() * -1;

        // Update transform style
        this.container.inner.style.setProperty(
            "transform",
            `translateX(${offset}px)`
        );
    }

    getProgress() {
        const outer = this.container.outer.getBoundingClientRect();
        const inner = this.container.inner.getBoundingClientRect();

        return (1 - (outer.bottom - inner.bottom) / (outer.height - inner.height));
    }

    updateOuterContainerHeight() {
        if (innerWidth < this.breakpoint) {
            this.container.outer.removeAttribute("style");
            return;
        }

        this.container.outer.style.setProperty(
            "height",
            `${this.getContainerHeight()}px`
        );
    }

    getContainerHeight() {
        // Everything under ~ 1.2 * innerHeight appears to be too fast
        return Math.max(innerHeight * 1.2, this.getTransformDistance());
    }

    getTransformDistance() {
        return (
            this.container.inner.scrollWidth -
            this.container.inner.getBoundingClientRect().width
        );
    }
}

document.querySelectorAll('[data-component="ImageSlider"]').forEach((el) => {
    new ImageSlider(el);
});
