import { getCookies, setCookies } from './helpers'

const win = window

export default class GdprConsent {
  constructor(rootNode) {
    this.$refs = {
      rootNode,
      bodyElement: document.body,
      toggleItems: [...rootNode.querySelectorAll('.script-list-item.is-toggable')],
      buttons: [...rootNode.querySelectorAll('.script-actions-btn')],
      checkboxes: [...rootNode.querySelectorAll('.GdprSwitch input')]
    }

    this.activateToggle();    

    this.cookies = getCookies()
    this.tagManagerId = rootNode.dataset.gtm
    this.isModal = rootNode.dataset.isModal || false
    this.preventPopUp = rootNode.dataset.preventPopUp === 'true' || false
    // if no backend-config provided, stop
    if (!this.tagManagerId) return

    this.init()
    
    if (this.cookies && GdprConsent.hasAnyTrueValue()) {
      GdprConsent.enableGtm(this.tagManagerId)
      GdprConsent.fireCookieEvents()
    }

    if(this.preventPopUp && this.cookies === false){
      const cookies = this.$refs.checkboxes.reduce((cookies, checkbox) => {
        cookies[checkbox.name] = checkbox.checked;

        return cookies
      }, {})

      setCookies(cookies)
      GdprConsent.enableGtm(this.tagManagerId)
      GdprConsent.fireCookieEvents()
    }

    document.querySelectorAll('.gdpr-element').forEach((el) => {
      el.classList.add('gdpr-loaded');
      const trigger = el.querySelector('.gdpr-trigger');
      const isVideo = el.classList.contains('gdpr-video');
      const background = el.querySelector('.gdpr-background');
      const content = el.querySelector('.gdpr-content');

      if(content) {
        const template = content.querySelector('script');
        const text = el.querySelector('.gdpr-text');

        trigger.addEventListener('click', (e) => {
          e.preventDefault();

          if (template.innerHTML) {
            if (isVideo) el.classList.add('gdpr-autoplay');

            prepareGdprContent(el, content, template, text, background);
          }
        });
      }
    })
  }

  init() {
    if (this.isModal && this.$refs.rootNode.dataset.hidden === 'false' && !this.cookies){
      this.openModal()
    }

    this.setCheckboxValues()

    this.setupInfoTogglers()
    this.setupBtnActions()
  }

  openModal() {
    this.$refs.bodyElement.classList.add('modal-active')
    this.$refs.rootNode.setAttribute('aria-hidden', false)
    this.$refs.rootNode.classList.add('show') // show modal
  }

  closeModal() {
    this.$refs.bodyElement.classList.remove('modal-active')
    this.$refs.rootNode.setAttribute('aria-hidden', true)
    this.$refs.rootNode.classList.remove('show') // show modal
  }

  setupInfoTogglers() {
    this.$refs.toggleItems.forEach((item) => {
      const btn = item.querySelector('.script-item-btn')

      btn.addEventListener('click', () => {
        item.classList.toggle('is-open')
      })
    })
  }

  setupBtnActions() {
    this.$refs.buttons.forEach((button) => {
      button.addEventListener('click', () => {
        const action = button.dataset.action

        // get all script-ids and save cookie-values
        if (action === 'accept' || action === 'some') {
          // get object with 'scriptId': 'yes|no' values
          const cookies = this.$refs.checkboxes.reduce((cookies, checkbox) => {
            cookies[checkbox.name] = checkbox.checked || action === 'accept' ? 'yes' : 'no'

            return cookies
          }, {})

          // save cookies
          setCookies(cookies)

          // fire events based on cookies and enable gtm
          GdprConsent.enableGtm(this.tagManagerId)
          GdprConsent.fireCookieEvents()
        }

        if(action === 'reject') {

          const cookies = this.$refs.checkboxes.reduce((cookies, checkbox) => {
            cookies[checkbox.name] = 'no'

            return cookies
          }, {})

          // save cookies
          setCookies(cookies)
        }

        if (this.isModal) this.closeModal()
      })
    })
  }

  activateToggle(){
    const toggles = Array.from(document.querySelectorAll('[data-component="GdprToggle"]'));
    
    if(toggles.length == 0) return;

    toggles.forEach(toggle => {
      toggle.addEventListener('click', () => {
        this.openModal();
        this.setupInfoTogglers();
        this.setupBtnActions();
      })
    })
  }

  // set checkbox values based on current cookies
  setCheckboxValues() {
    const cookies = getCookies()

    Object.keys(cookies).forEach((scriptId) => {
      const input = document.getElementById(`checkbox-${scriptId}`)

      if (scriptId === 'must') input.checked = true;
      else if (cookies[scriptId] === 'no') input.checked = false
      else if (cookies[scriptId] === 'yes') input.checked = true
    })
  }

  // loads script tag
  static enableGtm(tagManagerId) {
    GdprConsent.loadScript(tagManagerId)
  }

  // fire gtm events based on cookie-consent data
  static fireCookieEvents() {
    if (!GdprConsent.hasAnyTrueValue()) return

    const cookies = getCookies()

    Object.keys(cookies).forEach((scriptId) => {
      if (cookies[scriptId] === 'yes') {
        GdprConsent.trackEvent(scriptId, 'yes')
      }
    })

    GdprConsent.trackEvent('acc_consent_ready', 'yes')

    window.dispatchEvent(new CustomEvent('accConsentReady', {detail: { layers: cookies }} ));
  }

  // check if any cookie (script) has the value 'yes'
  static hasAnyTrueValue() {
    const cookies = getCookies()

    return Object.keys(cookies).some((k) => k!== 'must' && cookies[k] === 'yes');
  }

  // appends gtm script tag
  static loadScript(tagManagerId) {
    if (!tagManagerId) return

    (function(w, d, s, l, i) {
      w[l] = w[l] || []

      w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js',
      })

      const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : ''

      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', tagManagerId)
  }

  static trackEvent(event, value) {
    const dataLayer = win.dataLayer || []
    const tmp = {};
    tmp['event'] = event;
    tmp[tmp['event']] = 'yes';

    dataLayer.push(tmp)
  }
}

/**
 * Triggered by cookie plugin
 */
window.addEventListener('accConsentReady', function(event) {
  try {
    if (event.detail && event.detail['layers']) {
      Object.keys(event.detail['layers']).forEach(key => {
        document.querySelectorAll('.gdpr-element[data-gdpr-id="'+key+'"]').forEach((element) => {
          const background = element.querySelector('.gdpr-background');
          const content = element.querySelector('.gdpr-content');
          if(content) {
            const template = content.querySelector('script.template');
            const text = element.querySelector('.gdpr-text');

            if (event.detail['layers'][key] === 'yes') {
              prepareGdprContent(element, content, template, text, background);
            }
          }
        });
      })
    }
  } catch (e) {
  }
});

/**
 * Prepare content by loading obligatory scripts before initializing the content
 * @param element
 * @param content
 * @param template
 * @param text
 * @param background
 */
function prepareGdprContent(element, content, template, text, background) {
  content.insertAdjacentHTML('beforebegin', template.innerHTML);
  content.remove();
  text.remove();

  var _scripts = element.querySelectorAll('gdpr-script');
  var _scriptsCount = 0;

  if (_scripts.length > 0) {
    _scripts.forEach(el => {
      addGdprScript(el.getAttribute('src'), () => {
        _scriptsCount++;
        if (_scriptsCount === _scripts.length) {
          initGdprContent(element, content, template, text, background);
        }
      });
    });
  } else {
    initGdprContent(element, content, template, text, background);
  }
}

/**
 * Internal function to initialize gdpr restricted content
 * @param element
 * @param content
 * @param template
 * @param text
 * @param background
 */
function initGdprContent(element, content, template, text, background) {
  if(template && template.innerHTML) {
    element.classList.remove('gdpr-stop');

    if (background) {
      background.remove();
    }

    if (text) {
      text.remove();
    }

    window.dispatchEvent(new CustomEvent('accConsentShowItem', {detail: { element: element }} ));
  }
}

/**
 * Load javascript
 * @param src
 * @param callback
 */
function addGdprScript( src, callback ) {
  try {
    const s = document.createElement('script');
    s.setAttribute('src', src);
    s.onload = callback;
    document.body.appendChild(s);
  } catch (e) {
  }
}
